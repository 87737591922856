export const metrikaForm1 = () => {
    window.ym(87675093,'reachGoal','forma1');
}

export const metrikaForm2 = () => {
    window.ym(87675093,'reachGoal','forma2')
}

export const metrikaForm3 = () => {
    window.ym(87675093,'reachGoal','forma3')
}

export const metrikaForm4 = () => {
    window.ym(87675093,'reachGoal','forma4')
}